import React from "react"
import { Link } from "gatsby"
import { BiCaretRight, BiFolderOpen, BiHomeCircle } from "react-icons/bi"

const Breadcrumb = props => {
  const Arrow = () => {
    return <BiCaretRight className="inline mx-2" />
  }
  return (
    <ol
      itemScope
      itemType="https://schema.org/BreadcrumbList"
      className="flex items-center text-gray-700 font-bold text-sm"
    >
      <li
        itemProp="itemListElement"
        itemScope
        itemType="https://schema.org/ListItem"
        className="flex items-center"
      >
        <BiHomeCircle className="inline-block text-blue-400 w-5 h-5 mr-1" />
        <Link className="hover:underline inline-block" itemProp="item" to="/">
          <span itemProp="name">ホーム</span>
        </Link>
        <meta itemProp="position" content="1" />
        <Arrow />
      </li>
      <li
        itemProp="itemListElement"
        itemScope
        itemType="https://schema.org/ListItem"
        className="flex items-center"
      >
        <BiFolderOpen className="inline-block text-blue-400 w-5 h-5 mr-1" />
        <Link
          className="hover:underline inline-block"
          itemProp="item"
          to={props.link}
        >
          <span itemProp="name">{props.title}</span>
        </Link>
        <meta itemProp="position" content="2" />
        <Arrow />
      </li>
    </ol>
  )
}

export default Breadcrumb
