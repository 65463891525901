import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BiFolderOpen } from "react-icons/bi"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Sidebar from "../components/sidebar"
import MainWrapper from "../components/mainWrapper"
import Breadcrumb from "../components/breadcrumb"
import Links from "../components/links"

const BlogCategory = ({ data, location, pageContext }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { category } = pageContext
  const { edges } = data.allMarkdownRemark
  const seoTitle = `${category} に関する記事一覧`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={seoTitle} />
      <div className="lg:flex lg:justify-between">
        <section>
          <MainWrapper>
            <div className="bg-white rounded p-4 md:p-8">
              <Breadcrumb title={category} link={`/category/${category}/`} />
              <h1
                itemProp="headline"
                className="font-bold md:text-xl my-8 md:mb-4"
              >
                <BiFolderOpen className="inline-block text-blue-400 w-5 h-5 mr-1" />
                {seoTitle}
              </h1>
            </div>
            <ol className="grid grid-cols-1">
              {edges.map(({ node }) => {
                const title = node.frontmatter.title
                return (
                  <li
                    key={node.fields.slug}
                    className="bg-white px-4 pb-2 md:px-8 md:pb-4 group last:pb-4 md:last:pb-8"
                  >
                    <article
                      className="flex"
                      itemScope
                      itemType="http://schema.org/Article"
                    >
                      <Link
                        to={node.fields.slug}
                        className="block w-1/3"
                        itemProp="url"
                      >
                        <GatsbyImage
                          image={getImage(node.frontmatter.hero)}
                          alt={node.frontmatter.title}
                          itemProp="image"
                          className="group-hover:opacity-80 duration-300"
                        />
                      </Link>
                      <Link
                        to={node.fields.slug}
                        itemProp="url"
                        className="w-2/3 flex flex-col pl-2 md:p-4 duration-300 group-hover:bg-gray-100"
                      >
                        <header className="flex-1">
                          <h2 className="font-bold">
                            <span itemProp="headline">{title}</span>
                          </h2>
                        </header>
                        <div className="text-sm font-bold text-gray-700">
                          <time
                            dateTime={node.frontmatter.date}
                            className="inline-block"
                          >
                            {node.frontmatter.date}
                          </time>
                        </div>
                      </Link>
                    </article>
                  </li>
                )
              })}
            </ol>
            <section>
              <Links link="/page/" title="記事一覧" />
            </section>
          </MainWrapper>
        </section>
        <section>
          <Sidebar />
        </section>
      </div>
    </Layout>
  )
}

export default BlogCategory

export const pageQuery = graphql`
  query ($category: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            category
            hero {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  width: 1200
                  height: 750
                  formats: [AUTO, WEBP, AVIF]
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`
